import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Col, Form, InputGroup, Card, Button } from "react-bootstrap";
import ContactStyle from "../Contact.module.css";
import { useContactContext } from "../../Context/ContactContext";
import MyPaymentForm from "Components/Sqare/PaymentForm";
import Contact from "../Contact";

const IndividualDown = () => {
  const { individualDown, setIndividualDown } = useContactContext();
  const nameContact = ContactStyle.nameContact;
  const btnContact = ContactStyle.btnContact;

  const contact = ContactStyle.contact
  const subscribeFormStyle = ContactStyle.subscribeFormStyle
  const subscribeH1 = ContactStyle.subscribeH1

  const [selected, setSelected] = useState(false);

  const showMessage = (e) => {
    if (individualDown === "No I am not") {
      setSelected(true);
    } else {
      setSelected(false);
    }
  };

  console.log("eventDropDown", individualDown);

  useEffect(() => {
    showMessage();
  }, [individualDown]);

  // console.log("IndividualDown", IndividualDown);
  return (
    <>
      {/* <Form.Label className={labelForm}>Name</Form.Label> */}


      <section className={contact} style={{ display: "block", padding: "5rem 1rem" }}>
        <h1 className={subscribeH1}>EVENT PAYMENT</h1>
        <Card style={{ padding: ".5rem" }}>
          <Card.Text          >
            <b>Please note</b>: There is a $27.00 registration fee to cover the
            cost of your t-shirt for this event. Once Payment is received, you
            will be taken to the registration form.
          </Card.Text>
        </Card>


        <br />
        <br />
        <MyPaymentForm />
      </section>
    </>
  );
};
export default IndividualDown;
